<template>
  <div>
      <span>
       <el-autocomplete
          v-model="addressKeyword"
          placeholder="请输入地址来直接查找相关位置"
          @input="mapInput"
          clearable
          style="margin-bottom:20px;width:100%;"
          :fetch-suggestions="querySearch"
          @select="handleSelect"
          >
          <el-button slot="append" icon="el-icon-search" @click="getAddressKeyword">搜索</el-button>
       </el-autocomplete>
        <div id="container" style="width:100%;height:400px;"></div>
      </span>
  </div>
</template>
<script type="model">
 import { TMap } from '@/api/TMap.js'
//  import maps from 'qqmap'
 import { jsonp } from 'vue-jsonp'
export default {
  data() {
      return {
        map: null,
        markersArray: [],
        geocoder: '',
        searchService: [],
        restaurants: [],
        getAddress: null,
        getAddCode: null,
        addressKeyword: '',
        shopInfo: {
          lng: '',
          lat: '',
          addr: ''
        }
        // longitude: 21.43527,
        // latitude: 109.191269
      }
  },
  props: {
    visible: Boolean,
    form: Object
  },
  mounted() {
     this.init()
  },
  methods: {
     // 初始化地图
    init() {
      var that = this
      // TMap.init('BCIBZ-2SSKX-RAU4U-T53H5-M4565-KJF3D', () => {
      TMap('PA6BZ-XZUKW-6OBRF-O6HYQ-J3AYH-2DBUM').then(qq => {
        var myLatlng = new qq.maps.LatLng(this.form.latitude, this.form.longitude)
        console.log('latitude', this.form.latitude, 'longitude', this.form.longitude)
        var myOptions = {
          zoom: 13,
          center: myLatlng,
          mapTypeId: qq.maps.MapTypeId.ROADMAP
        }
        that.map = new qq.maps.Map(
          document.getElementById('container'),
          myOptions
        )
        const marker = new qq.maps.Marker({
            map: that.map,
            position: myLatlng
       })
        that.markersArray.push(marker)
        // 获取点击后的地址
        qq.maps.event.addListener(that.map, 'click', function(event) {
          // 获取点击后的地图坐标
          that.shopInfo.lng = event.latLng.getLng().toFixed(8)
          that.shopInfo.lat = event.latLng.getLat().toFixed(8)
          that.getAddressCode()
          const latLng = new qq.maps.LatLng(that.shopInfo.lat, that.shopInfo.lng)
          if (that.markersArray) {
            that.markersArray.forEach(item => {
              item.setMap(null)
            })
          }
          const marker = new qq.maps.Marker({
            map: that.map,
            position: latLng
          })
          that.markersArray.push(marker)
        })
        // 调用地址显示地图位置并设置地址
        that.getAddress = new qq.maps.Geocoder({
          complete: function(result) {
            that.map.setCenter(result.detail.location)
            that.shopInfo.lng = result.detail.location.lng
            that.shopInfo.lat = result.detail.location.lat
            if (that.markersArray) {
              that.markersArray.forEach(item => {
                item.setMap(null)
              })
            }
            const latLng = new qq.maps.LatLng(result.detail.location.lat, result.detail.location.lng)
            const marker = new qq.maps.Marker({
              map: that.map,
              position: latLng
            })
            that.markersArray.push(marker)
            that.$emit('getPlace', that.shopInfo)
          }
        })
        // 通过坐标来显示地图地址
        that.getAddCode = new qq.maps.Geocoder({
          complete: function(result) {
            that.addressKeyword = result.detail.address
            that.shopInfo.addr = result.detail.address
            that.$emit('getPlace', that.shopInfo)
          }
        })
      })
    },
    querySearch(queryString, cb) {
      console.log(queryString)
      var restaurants = this.restaurants
      var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0)
      }
    },
    handleSelect(item) {
      this.getAddressKeyword1(item.address)
    },
    mapInput (e) {
      const that = this
      jsonp('https://apis.map.qq.com/ws/place/v1/suggestion', {
        output: 'jsonp',
        keyword: e,
        region: '中国',
        key: 'PA6BZ-XZUKW-6OBRF-O6HYQ-J3AYH-2DBUM'
      })
        .then(function (response) {
          const arr = response.data
          arr.forEach(item => {
            item.value = item.title
          })
          that.restaurants = arr
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    getAddressKeyword1 (e) {
      this.getAddress.getLocation(e)
    },
    // 通过地址获得位置
    getAddressKeyword() {
      // 通过getLocation();方法获取位置信息值
       this.getAddress.getLocation(this.addressKeyword)
    },
    // 通过坐标获得地址
    getAddressCode() {
      var lat = parseFloat(this.shopInfo.lat)
      var lng = parseFloat(this.shopInfo.lng)
      var latLng = new qq.maps.LatLng(lat, lng)
      // 调用获取位置方法
      this.getAddCode.getAddress(latLng)
    },
    clearable() {
      this.addressKeyword = ''
    }
    }
}
</script>
<style>
.serachinput {
  width: 300px;
  box-sizing: border-box;
  padding: 9px;
  border: 1px solid #dddee1;
  line-height: 20px;
  font-size: 16px;
  height: 38px;
  color: #333;
  position: relative;
  border-radius: 4px;
  -webkit-box-shadow: #666 0px 0px 10px;
  -moz-box-shadow: #666 0px 0px 10px;
  box-shadow: #666 0px 0px 10px;
}
::v-deep .el-dialog__header {
  border-bottom: 0 !important;
}
</style>
