export function TMap() {
  return new Promise(function(resolve, reject) {
    window.init = function() {
      resolve(qq)
    }
    var script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = 'https://map.qq.com/api/js?v=2.exp&callback=init&key=PA6BZ-XZUKW-6OBRF-O6HYQ-J3AYH-2DBUM'
    script.onerror = reject
    document.head.appendChild(script)
  })
}
